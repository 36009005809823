const endpoint = "https://smh1xgkn09.execute-api.us-east-1.amazonaws.com/default/findYtVideosInWp";
const querystring = require("querystring");

export default function send(options, callback){
    let params = {
      hostname: options.hostname,
      auth: "Basic " + btoa(options.username + ":" + options.password)
    };

    fetch(endpoint + "?" + querystring.stringify(params), {
      method: 'GET',
      mode: 'cors',
      headers: {
        'content-type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(response => {
        if(response.status === "success") {
            callback(null, response.result);
        } else {
            callback(response.error);
        }
      })
      .catch((err) => {
        callback(err);
      });
  }