import Amplify, { Auth } from 'aws-amplify'
import { withAuthenticator } from 'aws-amplify-react'
import awsweb from './aws-exports'
import React from "react"
import { BrowserRouter as Router, Route } from "react-router-dom"
import SingleUse from './containers/app/SingleUse'
import Dashboard from './containers/app/dashboard'
import Websites from './containers/app/Websites'
import Home from './containers/Home'
import AddWebsite from './containers/app/AddWebsite'
import YoutubeAccounts from './containers/app/YoutubeAccounts'
import AddYoutubeAccount from './containers/app/AddYoutubeAccount'
import SignOut from './containers/app/SignOut'
import { SignIn, ConfirmSignIn, VerifyContact, ConfirmSignUp, ForgotPassword, RequireNewPassword, SignUp } from 'aws-amplify-react/dist/Auth'
import { connect } from 'react-redux'
import { registerUser } from './actions/user'

// Add mustensih-api's endpoint to amplify configuration
awsweb.aws_cloud_logic_custom.push({
  "name": "mustensih-api",
  "endpoint": "https://jwf9ebbws1.execute-api.us-east-1.amazonaws.com/Prod/",
  "region": "us-east-1"
})
Amplify.configure(awsweb)

/**
 * Skeleton of Mustensih web application. Setting routes and amplify
 * configurations here.
 */
class Website extends React.Component {

  componentDidMount() {
    const { registerUser } = this.props
    Auth.currentUserInfo()
        .then(user => registerUser(user))
  }

  render() {
    return (
      <Router>
        <div>
          <Route exact path="/" component={Home} />
          <Route path="/singleuse" component={SingleUse} />
          <Route path="/app/dashboard" component={Dashboard} />
          <Route exact path="/app/websites" component={Websites} />
          <Route exact path="/app/websites/add" component={AddWebsite} />
          <Route exact path="/app/youtubeaccounts" component={YoutubeAccounts} />
          <Route exact path="/app/youtubeaccounts/add" component={AddYoutubeAccount} />
          <Route exact path="/signout" component={SignOut} />
        </div>
      </Router>
    )
  }
}

export default connect(null, { registerUser })(withAuthenticator(Website, false, [
  <SignIn/>,
  <SignUp/>,
  <ConfirmSignIn/>,
  <VerifyContact/>,
  <ConfirmSignUp/>,
  <ForgotPassword/>,
  <RequireNewPassword />
]))
