import { combineReducers } from 'redux'
import websites from './websites'
import user from './user'
import accounts from './accounts'
import posts from './posts'

export default combineReducers({
  websites,
  user,
  accounts,
  posts
})