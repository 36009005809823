import { API } from 'aws-amplify'
import { fetchWebsites } from './websites'
import { fetchAccounts } from './accounts'

export const REGISTER_USER_REQUEST = "REGISTER_USER_REQUEST"
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS"
export const REGISTER_USER_FAILURE = "REGISTER_USER_FAILURE"

export const registerUser = user => (dispatch, getState) => {
  dispatch({ type: REGISTER_USER_REQUEST })

  API.get('mustensih-api', `/user?userId=${user.id}`)
      .then(response => {
        dispatch({ type: REGISTER_USER_SUCCESS, user: { ...user, ...response } })
        fetchWebsites()(dispatch, getState)
        fetchAccounts()(dispatch, getState)
      })
      .catch((error) => {
        dispatch({ type: REGISTER_USER_FAILURE, error })
      });
}
