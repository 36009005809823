import { API } from 'aws-amplify'

export const FETCH_POSTS_REQUEST = 'FETCH_POSTS_REQUEST'
export const FETCH_POSTS_SUCCESS = 'FETCH_POSTS_SUCCESS'
export const FETCH_POSTS_FAILURE = 'FETCH_POSTS_FAILURE'

export const fetchPosts = () => (dispatch, getState) => {
  const { user } = getState()
  if (!user.signedIn) return;
  
  dispatch({ type: FETCH_POSTS_REQUEST })
  
  API.get('mustensih-api', `/post?userId=${user.id}`)
      .then(response => {
        const posts = response.map(p => ({ ...p, videoPublishedAt: new Date(p.videoPublishedAt)}))
        posts.sort((p1, p2) => p1.videoPublishedAt.getTime() - p2.videoPublishedAt.getTime())
        dispatch({ type: FETCH_POSTS_SUCCESS, posts })
      })
      .catch(error => dispatch({ type: FETCH_POSTS_FAILURE, error }))
}

export const DELETE_POST_REQUEST = 'DELETE_POST_REQUEST'
export const DELETE_POST_SUCCESS = 'DELETE_POST_SUCCESS'
export const DELETE_POST_FAILURE = 'DELETE_POST_FAILURE'

export const deletePost = (post) => (dispatch, getState) => {
  const { user } = getState()
  if (!user.signedIn) return;
  
  console.log('will dispatch delete post request')
  dispatch({ type: DELETE_POST_REQUEST })

  API.post('mustensih-api', '/post', { body: { ...post, status: 'DELETED' }})
      .then(() => {
        dispatch({ type: DELETE_POST_SUCCESS })
        fetchPosts()(dispatch, getState);
      })
      .catch(error => dispatch({ type: DELETE_POST_FAILURE, error }))
}

export const APPROVE_POST_REQUEST = 'APPROVE_POST_REQUEST'
export const APPROVE_POST_SUCCESS = 'APPROVE_POST_SUCCESS'
export const APPROVE_POST_FAILURE = 'APPROVE_POST_FAILURE'

export const approvePost = (post) => (dispatch, getState) => {
  const { user } = getState()
  if (!user.signedIn) return;
  
  console.log('will dispatch approve post request')
  dispatch({ type: APPROVE_POST_REQUEST })

  API.post('mustensih-api', '/post', { body: { ...post, status: 'APPROVED' }})
      .then(() => {
        dispatch({ type: APPROVE_POST_SUCCESS })
        fetchPosts()(dispatch, getState);
      })
      .catch(error => dispatch({ type: APPROVE_POST_FAILURE, error }))
}

export const UPDATE_POST_REQUEST = 'UPDATE_POST_REQUEST'
export const UPDATE_POST_SUCCESS = 'UPDATE_POST_SUCCESS'
export const UPDATE_POST_FAILURE = 'UPDATE_POST_FAILURE'

export const updatePost = (post) => (dispatch, getState) => {
  const { user } = getState()
  if (!user.signedIn) return;
  
  console.log('will dispatch update post request')
  dispatch({ type: UPDATE_POST_REQUEST })

  API.post('mustensih-api', '/post', { body: post })
      .then(() => {
        dispatch({ type: UPDATE_POST_SUCCESS })
        fetchPosts()(dispatch, getState);
      })
      .catch(error => dispatch({ type: UPDATE_POST_FAILURE, error }))
}