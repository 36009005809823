import React from 'react';

export default class Header extends React.Component {
  render() {
    return (
      <div className="container header">
        <h2>{this.props.title}</h2>
        <p>{this.props.content}</p>
      </div>
    );
  }
}