import {
  ADD_WEBSITE_REQUEST,
  ADD_WEBSITE_SUCCESS, 
  ADD_WEBSITE_FAILURE,
  DELETE_WEBSITE_REQUEST,
  DELETE_WEBSITE_SUCCESS,
  DELETE_WEBSITE_FAILURE,
  FETCH_WEBSITES_REQUEST,
  FETCH_WEBSITES_SUCCESS,
  FETCH_WEBSITES_FAILURE} from '../actions/websites'

function websites(websites, action) {
  if (typeof websites === 'undefined') {
    return {
      inProgress: false,
      isSynced: false,
      list: []
    }
  }

  switch(action.type) {
    case ADD_WEBSITE_REQUEST:
    case ADD_WEBSITE_FAILURE:
    case DELETE_WEBSITE_REQUEST:
    case DELETE_WEBSITE_FAILURE:
      return {
        ...websites,
        inProgress: true
      }
    case ADD_WEBSITE_SUCCESS:
    case DELETE_WEBSITE_SUCCESS:
      return {
        ...websites,
        inProgress: false,
        isSynced: false
      }
    case FETCH_WEBSITES_REQUEST:
      return {
        ...websites,
        inProgress: true,
        isSynced: false
      }
    case FETCH_WEBSITES_SUCCESS:
      return {
        ...websites,
        inProgress: false,
        isSynced: true,
        list: action.websites
      }
    case FETCH_WEBSITES_FAILURE:
      return {
        ...websites,
        inProgress: false,
        isSynced: true,
        list: []
      }
    default:
      return websites
  }
}

export default websites