export default {
  mustensih: {
    'app.dashboard': 'Panel',
    'app.websites': 'Siteler',
    'app.youtube_accounts': 'Youtube Hesapları',
    'app.rules': 'Kurallar',
    'app.sign_out': 'Çıkış',

    // Dashboard page
    'app.dashboard.error.no_post_found': 'Bu durumda hiçbir gönderi bulunamadı',
    'app.dashboard.pending_approval': 'Onay Bekleyen',
    'app.dashboard.approved': 'Onaylanmış',
    'app.dashboard.published': 'Yayınlanmış',
    'app.dashboard.deleted': 'Silinmiş',

    'app.dashboard.action.approve': 'Onayla',
    'app.dashboard.action.edit': 'Düzenle',
    'app.dashboard.action.delete': 'Sil',

    'app.dashboard.post.category': 'Kategori: {{category}}',

    // Websites Page
    'app.websites.website': 'Site',

    // Add Website Page
    'app.websites.add.url': 'Site adresi',
    'app.websites.add.username': 'Kullanıcı adı',
    'app.websites.add.password': 'Şifre',

    // Sign out page
    'app.sign_out.info.signing_out': 'Oturum kapatılıyor...',

    // Youtube Accounts Page
    'app.youtube_accounts.youtube_account': 'YouTube Hesabı',

    // Add Youtube Account Page
    'app.youtube_accounts.add.channel_id': 'Kanal',

    // Post Edit Page
    'app.post_edit.title': 'Başlık',
    'app.post_edit.content': 'İçerik',
    
    // Generic
    'add': 'Ekle',
    'approve': 'Onayla',
    'edit': 'Düzenle',
    'delete': 'Sil',
    'save': 'Kaydet'
  }
};