import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Button, Card, CardActions, CardContent } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'


const styles = {
  root: {
    display: 'flex'
  },
  card: {
    minWidth: 275,
    marginRight: 25
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
};

const ItemCard = ({ classes, id, type, title, subtitle, onDelete, t }) => (
  <Card className={classes.card}>
    <CardContent>
      <Typography className={classes.title} color="textSecondary" gutterBottom>
        {type}
      </Typography>
      <Typography variant="h5" component="h2">
        {title}
      </Typography>
      <Typography className={classes.pos} color="textSecondary">
        {subtitle}
      </Typography>
    </CardContent>
    <CardActions>
      <Button size="small" onClick={() => onDelete(id)}>
        {t('delete')}
      </Button>
    </CardActions>
  </Card>
);

export default withStyles(styles)(
  withTranslation('mustensih')(ItemCard)
)
