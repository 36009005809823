import React from 'react';

export default class VideoItem extends React.Component {
  render() {
    let textToShow = this.props.wpContent;
    if (textToShow.length > 100) {
      textToShow = textToShow.substr(0, 200) + "...";
    }

    return (
      <div className="container video-item">
        <img className="video-item-img" src={this.props.thumbnail} alt={this.props.title} />
        <div className="content">
          <b>{this.props.title}</b>
          {textToShow}
          <p><i><b>Category:</b> {this.props.categoryName}</i></p>
        </div>
        <div className="buttons">
          <div className="btn-edit" onClick={this.props.onEdit}>
            <img src="img/edit.png" alt="Edit content" style={{width:"80%"}}></img>
          </div>
          <div className="btn-delete" onClick={this.props.onDelete}>
            <img src="img/basket.png" alt="Don't include this video" style={{width:"80%"}}></img>
          </div>
        </div>
      </div>
    );
  }
}