import React from 'react'
import { Auth } from 'aws-amplify'
import { Redirect } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

class SignOut extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isSignedOut: false
    }
  }

  componentDidMount() {
    Auth.signOut()
        .then(() => this.setState({ isSignedOut: true }))
  }

  render() {
    const { t } = this.props

    if (this.state.isSignedOut) {
      return (
        <Redirect to="/" />
      )
    } else {
      return (
        <div>
          <p>
            {t('app.sign_out.info.signing_out')}
          </p>
        </div>
      )
    }
  }
}

export default withTranslation('mustensih')(SignOut);
