const getYoutubeVideosUrl = "https://xa7jro4cbg.execute-api.us-east-1.amazonaws.com/test/getYTVideos";

export default function send(options, callback){
  fetch(getYoutubeVideosUrl, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify({
      username: options.username,
      from: options.startDate.toISOString(),
      to: options.endDate.toISOString()
    }),
    headers: {
      'content-type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      if (!Array.isArray(response) || response.length === 0) {
        callback("Couldn't find any video with this filter!");
      } else {
        callback(null, response);
      }
    })
    .catch((err) => {
      callback(err);
    });
}