import React from 'react';
import getWPCategories from '../requests/getWPCategories';
import findYtVideosInWp from '../requests/findYtVideosInWp';
import Cookies from 'universal-cookie';

const COOKIE_OPTS = {
  path: '/'
}

export default class WordpressInfo extends React.Component {
  constructor(props) {
    super(props);
    this.cookies = new Cookies();

    this.state = {
      hostname: this.cookies.get('wpHostname'),
      username: this.cookies.get('wpUsername'),
      password: "",
      requestInProgress: false,
      errorMessage: null
    };
  }

  handleHostnameChange(event) {
    this.setState(Object.assign({}, this.state, {
      hostname: event.target.value
    }));
  }

  handleUsernameChange(event) {
    this.setState(Object.assign({}, this.state, {
      username: event.target.value
    }));
  }

  handlePasswordChange(event) {
    this.setState(Object.assign({}, this.state, {
      password: event.target.value
    }));
  }

  handleNext() {
    this.setState(Object.assign({}, this.state, {
      requestInProgress: true,
      errorMessage: null
    }));

    let wpOptions = {
      hostname: this.state.hostname,
      username: this.state.username,
      password: this.state.password
    };

    getWPCategories(wpOptions, (err, categories) => {
      if (err) {
        this.requestCompleted(err);
      } else {

        // Set cookies
        this.cookies.set('wpHostname', this.state.hostname, COOKIE_OPTS);
        this.cookies.set('wpUsername', this.state.username, COOKIE_OPTS);

        findYtVideosInWp(wpOptions, (err, embeddedVideos) => {
          if (err) {
            this.requestCompleted(err);
          } else {
            this.requestCompleted();

            this.props.onNext({
              ...wpOptions,
              categories,
              embeddedVideos
            });
          }
        });
      }
    });
  }

  requestCompleted(err) {
    this.setState(Object.assign({}, this.state, {
      requestInProgress: false,
      errorMessage: err ? err.toString() : null
    }));
  }

  render() {
    return (
      <div className="container">
        {/* HEADER */}
        <div className="form-header" align="center">
          <span className="header">Enter Wordpress Site Information</span><br/>
          <span className="small">This information is required to connect your Wordpress blog</span>
          <hr />
        </div>
        {/* ERROR AREA */}
        { this.state.errorMessage &&
          <div className="container error" visible="false">
            {this.state.errorMessage}
          </div>
        }
        {/* FORM */}
        <div className="container">
          Hostname:
          <input type="text" placeholder="www.example.org" value={this.state.hostname} onChange={this.handleHostnameChange.bind(this)} />
        </div>
        <div className="container">
          Username:
          <input type="text" placeholder="Enter username" value={this.state.username} onChange={this.handleUsernameChange.bind(this)} />
        </div>
        <div className="container">
          Password:
          <input type="password" placeholder="Enter password (api key)" value={this.state.password} onChange={this.handlePasswordChange.bind(this)} />
        </div>
        {/* BUTTONS */}
        <div className="container" style={{flexDirection:"row", width:"100%", marginTop:"10px"}}>
          <button className="button secondary" style={{marginRight:"5px", flexGrow:1}} onClick={this.props.onBack}>
            Back
          </button>
          <button className="button" style={{marginLeft:"5px", flexGrow:1}} onClick={this.handleNext.bind(this)} disabled={this.state.requestInProgress}>
            Next
          </button>
        </div>
      </div>
    );
  }
}
