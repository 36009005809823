const createWPPostUrl = "https://ooryk2m0ua.execute-api.us-east-1.amazonaws.com/test/createwpposts";

export default function send(options, callback) {
  fetch(createWPPostUrl, {
    method: 'POST',
    mode: 'cors',
    body: encodeURIComponent(JSON.stringify({
      hostname: options.hostname,
      auth: "Basic " + btoa(options.username + ":" + options.password),
      videos: options.videos
    })),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(response => response.json())
    .then(response => {
      if (response.error) {
        callback(response.error);
      } else {
        callback(null);
      }
    })
    .catch((err) => {
      callback(err.toString());
    });
}