import React from 'react';

export default class PreviewVideoItem extends React.Component {
  render() {
    let title = this.props.title;

    return (
      <div className="container preview-video-item">
        <img className="video-item-img" src={this.props.thumbnail} alt={this.props.title} />
        <div className="content">
          <b>{title}</b>
        </div>
      </div>
    );
  }
}