import React from 'react';
import NavigationItem from './NavigationItem';

export default class Navigation extends React.Component {
  getState(itemOrder) {
    if (itemOrder < this.props.step) return "done";
    else if (itemOrder === this.props.step) return "current";
    else return "disabled";
  }

  render() {
    return (
      <div className="navigation">
        <NavigationItem text="Wordpress Info" position="first" state={this.getState(0)} />
        <NavigationItem text="YouTube Info" state={this.getState(1)} />
        <NavigationItem text="Template" state={this.getState(2)} />
        <NavigationItem text="Confirm Videos" state={this.getState(3)} />
        <NavigationItem text="Done" state={this.getState(4)} />
      </div>
    );
  }
}