import React from 'react';

export default class Template extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: props.template.content,
      embed: props.template.embed
    }
  }

  handleContentChange = (event) => {
    this.setState(Object.assign({}, this.state, {
      content: event.target.value
    }));
  }

  handleWidthChange = (event) => {
    this.setState(Object.assign({}, this.state, {
      embed: {
        width: event.target.value,
        height: this.state.embed.height
      }
    }));
  }

  handleHeightChange = (event) => {
    this.setState(Object.assign({}, this.setState, {
      embed: {
        width: this.state.embed.width,
        height: event.target.value
      }
    }));
  }

  render() {
    return (
      <div className="container">
        <div className="form-header" align="center">
          <span className="header">You Can Change Template Here</span><br />
          <span className="small">Template defined here will be used for creating wordpress pages</span>
          <hr />
        </div>
        <div className="container">
          Content:
          <textarea
            rows={8}
            value={this.state.content}
            onChange={this.handleContentChange}
          />
        </div>
        <div className="container" style={{flexDirection:"row"}}>
          <div className="container" style={{flexGrow: 1}}>
            Embed Width:
            <input type="text" value={this.state.embed.width} onChange={this.handleWidthChange} />
          </div>
          <div className="container" style={{flexGrow: 1}}>
            Embed Height:
            <input type="text" value={this.state.embed.height} onChange={this.handleHeightChange} />
          </div>
        </div>
        <div className="container" style={{flexDirection:"row", width:"100%", marginTop:"10px"}}>
          <button className="button secondary" style={{marginRight:"5px", flexGrow: 1}} onClick={this.props.onBack}>
            Back
          </button>
          <button className="button" style={{marginLeft:"5px", flexGrow:1}} onClick={() => this.props.onNext(this.state)}>
            Next
          </button>
        </div>
      </div>
    )
  }
}