import React from 'react'
import Root from './Root'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import { addAccount } from '../../actions/accounts'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 500
  },
  button: {
    height: 30
  }
}

class AddYoutubeAccount extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      channelId: ''
    }
  }

  onAddClick() {
    const { addAccount } = this.props
    addAccount({
      accountId: this.state.channelId,
    })
  }

  handleChannelIdChange(event) {
    this.setState({
      channelId: event.target.value
    })
  }

  render() {
    const { classes, t } = this.props

    return(
      <Root>
        <div className={classes.root}>
          <TextField
            id='channelId'
            label={t('app.youtube_accounts.add.channel_id')}
            margin='normal'
            variant='outlined'
            onChange={event => this.handleChannelIdChange(event)}
            ref={this.accountIdRef}
          />
          <Button variant='contained' color='primary' onClick={() => this.onAddClick()}>
            {t('add')}
          </Button>
        </div>
      </Root>
    )
  }
}

const mapDispatchToProps = {
  addAccount
}

export default connect(null, mapDispatchToProps)(
  withStyles(styles)(
    withTranslation('mustensih')(AddYoutubeAccount)
  )
)
