import { API } from 'aws-amplify'

// Add Website

export const ADD_WEBSITE_REQUEST = "ADD_WEBSITE_REQUEST"
export const ADD_WEBSITE_SUCCESS = "ADD_WEBSITE_SUCCESS"
export const ADD_WEBSITE_FAILURE = "ADD_WEBSITE_FAILURE"

export const addWebsite = (website) => (dispatch, getState) => {
  const { user } = getState()
  if (!user.signedIn) return

  dispatch({ type: ADD_WEBSITE_REQUEST })

  const params = { 
    body: {
      website,
      userId: user.id
    }
  }

  API.post('mustensih-api', '/user/website', params)
    .then(() => dispatch({ type: ADD_WEBSITE_SUCCESS, website }))
    .catch(error => dispatch({ type: ADD_WEBSITE_FAILURE, error }))
}

// Fetch Websites
export const FETCH_WEBSITES_REQUEST = "FETCH_WEBSITES_REQUEST"
export const FETCH_WEBSITES_SUCCESS = "FETCH_WEBSITES_SUCCESS"
export const FETCH_WEBSITES_FAILURE = "FETCH_WEBSITES_FAILURE"

export const fetchWebsites = () => (dispatch, getState) => {
  const { user } = getState()
  if (!user.signedIn) return

  dispatch({ type: FETCH_WEBSITES_REQUEST })

  API.get('mustensih-api', `/user/website?userId=${user.id}`)
    .then(response => dispatch({ type: FETCH_WEBSITES_SUCCESS, websites: response }))
    .catch(error => dispatch({ type: FETCH_WEBSITES_FAILURE, error }))
}

// Delete Website
export const DELETE_WEBSITE_REQUEST = "DELETE_WEBSITE_REQUEST"
export const DELETE_WEBSITE_SUCCESS = "DELETE_WEBSITE_SUCCESS"
export const DELETE_WEBSITE_FAILURE = "DELETE_WEBSITE_FAILURE"

export const deleteWebsite = (hostname) => (dispatch, getState) => {
  const { user } = getState()
  if (!user.signedIn) return
  
  dispatch({ type: DELETE_WEBSITE_REQUEST })

  const params = { 
    body: {
      website: { hostname },
      userId: user.id
    }
  }

  API.del('mustensih-api', '/user/website', params)
    .then(() => dispatch({ type: DELETE_WEBSITE_SUCCESS }))
    .catch(error => dispatch({ type: DELETE_WEBSITE_FAILURE, error}))
}
