import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './locales/en';
import tr from './locales/tr';

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
      resources: {
        en,
        tr
      },
      fallbackLng: 'en',
      debug: true,
      ns: ['mustensih'],
      defaultNS: 'mustensih',
      keySeparator: false,
      interpolation: {
        escapeValue: false,
        formatSeparator: ','
      },
      react: {
        wait: true
      }
    });
  
export default i18n;