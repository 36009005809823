import React from 'react'
import { Box } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import PostItem from './PostItem'
import { withStyles } from '@material-ui/styles'
import { withTranslation } from 'react-i18next'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  itemsBox: {
    display: 'flex',
    flexWrap: 'wrap'
  }
}

function DashboardPostsTab(props) {
  const { classes, posts, websites, status, value, index, deletePost, approvePost, handleEditClick, t, ...other } = props;
  const currentTabPosts = posts.all.filter(p => p.status === status);
  const [currentPage, setPage] = React.useState(1);
  const PAGE_SIZE = 12;
  const handleChange = (event, value) => {
      setPage(value);
  };

  return (
    <Box
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3} color="primary" className={classes.root}>
        <Pagination 
          count={Math.ceil(currentTabPosts.length/PAGE_SIZE)}
          onChange={handleChange}
          hidden={currentTabPosts.length === 0}
          style={{alignSelf: "center"}}
        />
        <div className={classes.itemsBox}>
        { currentTabPosts.slice((currentPage-1)*PAGE_SIZE, currentPage*PAGE_SIZE).map(p => (
            <PostItem
                key={p.videoId}
                image={p.thumbnail}
                title={p.title}
                content={p.content}
                status={p.status}
                website={websites.find(w => w.hostname === p.hostname)}
                categoryId={p.categories[0]}
                onDelete={() => deletePost(p)}
                onApprove={() => approvePost(p)}
                onEdit={() => handleEditClick(p)}
            />
            ))
        }
        { posts.all.filter(p => p.status === status).length === 0 &&
            t('app.dashboard.error.no_post_found')
        }
        </div>
      </Box>
    </Box>
  );
}

export default withStyles(styles)(
  withTranslation('mustensih')(DashboardPostsTab)
)