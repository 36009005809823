import React from 'react';

export default class CompletionInfo extends React.Component {
  render() {
    return (
      <div className="container">
        {/* HEADER */}
        <div className="form-header" align="center">
          <span className="header">Congratulations! You've successfuly created {this.props.videos.length} post(s). They're created as draft, to see them in action you need to publish them first.</span>
        </div>
        <div className="container">
          <button className="button" onClick={this.props.onDone}>
            Done
          </button>
        </div>
      </div>
    );
  }
}
