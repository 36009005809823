import React from 'react'
import PropTypes from 'prop-types'
import ItemCard from './ItemCard'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'


const styles = {
  root: {
    display: 'flex'
  }
}

const ItemCardList = ({ classes, items, onDeleteClick }) => (
  <div className={classes.root}>
    {items.map((item, index) => (
      <ItemCard
        key={item.id}
        {...item}
        onDelete={onDeleteClick}
      />
    ))}
  </div>
)

ItemCardList.propTypes = {
  items: PropTypes.array.isRequired,
  onDeleteClick: PropTypes.func.isRequired
}

export default connect(null, null)(withStyles(styles)(ItemCardList))