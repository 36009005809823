import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import requestUser from '../requests/youtubeUser';
import requestVideos from '../requests/youtubeVideos';
import Cookies from 'universal-cookie';
import PreviewVideoItem from '../components/PreviewVideoItem.js';

import 'react-datepicker/dist/react-datepicker.css';

const COOKIE_OPTS = {
  path: '/'
}

export default class YouTubeInfo extends React.Component {

  constructor(props) {
    super(props);
    this.cookies = new Cookies();

    this.state = {
      requestInProgress: false,
      username: this.cookies.get('youtubeUsername'),
      startDate: moment("01/01/2018"),
      endDate: moment(),
      errorMessage: null,
      userInfo: null,
      findInProgress: false,
      videos: null,
      embeddedVideos: props.embeddedVideos
    };
  }

  handleChannelNameChange(event) {
    this.setState(Object.assign({}, this.state, {
      username: event.target.value
    }));
  }

  handleStartDateChange(date) {
    this.setState(Object.assign({}, this.state, {
      startDate: date
    }));
  }

  handleEndDateChange(date) {
    this.setState(Object.assign({}, this.state, {
      endDate: date
    }));
  }

  handleFetch = (cb) => {
    this.setState(Object.assign({}, this.state, {
      requestInProgress: true,
      errorMessage: null
    }));

    requestVideos(
      {
        username: this.state.username,
        startDate: this.state.startDate,
        endDate: this.state.endDate
      },
      (err, result) => {
        if (err) {
          this.setState(Object.assign({}, this.state, {
            requestInProgress: false,
            errorMessage: err.toString(),
            videos: null
          }));
        } else {
          this.cookies.set('youtubeUsername', this.state.username, COOKIE_OPTS);
          let videos = removeAlreadyEmbeddedVideos(result, this.state.embeddedVideos);
          this.setState(Object.assign({}, this.assign, {
            requestInProgress: false,
            errorMessage: null,
            videos: videos
          }));

          if (cb instanceof Function) {
            cb(videos);
          }
        }
      }
    );
  }

  handleFind() {
    this.setState(Object.assign({}, this.state, {
      errorMessage: null,
      findInProgress: true
    }));

    requestUser(this.state.username, (err, userInfo) => {
      if (err) {
        this.setState(Object.assign({}, this.state, {
          errorMessage: err.toString(),
          findInProgress: false
        }));
      } else {
        this.setState(Object.assign({}, this.state, {
          userInfo: userInfo,
          findInProgress: false
        }));
      }
    });
  }

  render() {
    return (
      <div className="container">
        {/* HEADER */}
        <div className="form-header" align="center">
          <span className="header">Please, enter YouTube channel information</span><br/>
          <span className="small">Given information will be used to filter videos</span>
          <hr />
        </div>
        {/* ERROR ROW */}
          {
            this.state.errorMessage &&
            <div className="container error" visible="false">
              {this.state.errorMessage}
            </div>
          }
        {/* YOUTUBE CHANNEL INPUT */}
        <div className="container">
          <div>
          YouTube Channel: <a href="https://support.google.com/youtube/answer/3250431?hl=en" target="_blank" rel="noopener noreferrer">?</a>
          </div>
          <div className="container" style={{flexDirection:"row"}}>
            <input
              type="text"
              placeholder="Enter the channel name in your channel's url"
              value={this.state.username}
              onChange={this.handleChannelNameChange.bind(this)}
              style={{
                width:"80%",
                borderTopRightRadius:0,
                borderBottomRightRadius:0
              }}
            />
            <button
              className="button"
              style={{
                width:"20%",
                borderTopLeftRadius:0,
                borderBottomLeftRadius: 0
              }}
              onClick={this.handleFind.bind(this)}
              disabled={this.state.findInProgress}
            >
              Check
            </button>
          </div>
        </div>
        {/* YOUTUBE CHANNEL INFO */}
        {
          this.state.userInfo &&
          <div
            className="container"
            style={{
              flexDirection:"row",
              alignItems:"center",
              padding: 10,
              borderRadius: 5,
              border: "1px solid rgb(225, 229, 234)"
            }}
          >
            <img
              src={this.state.userInfo.snippet.thumbnails.default.url}
              style={{
                width: 60,
                height: 60,
                borderRadius: 30
              }}
              alt={this.state.userInfo.snippet.title}
            />
            <h4 style={{paddingLeft:20}}>
              {this.state.userInfo.snippet.title}
            </h4>
          </div>
        }
        {/* DATE CONTAINER */}
        <div className="container" style={{flexDirection:"row"}}>
          <div className="container" style={{flexGrow:"1"}}>
            Start Date:
            <DatePicker
              selected={this.state.startDate}
              onChange={this.handleStartDateChange.bind(this)}
            />
          </div>
          <div className="container" style={{flexGrow:"1"}}>
            End Date:
            <DatePicker
              selected={this.state.endDate}
              onChange={this.handleEndDateChange.bind(this)}
            />
          </div>
        </div>
        {/* BUTTONS */}
        <Buttons
          onBack={this.props.onBack}
          onFetch={this.handleFetch}
          onNext={() => this.props.onNext(this.state.videos)}
          requestInProgress={this.state.requestInProgress}
          isVideosFetched={this.state.videos !== null && this.state.videos.length > 0}
        />
        {/* PREVIEW VIDEOS */}
        <PreviewVideos
          videos={this.state.videos}
        />
      </div>
    );
  }
}

class Buttons extends React.Component {
  render() {
    return (
      <div className="container" style={{flexDirection: "row"}}>
        <div className="container" style={{flexGrow: 1, marginRight: "2px"}}>
          <button className="button secondary" onClick={this.props.onBack}>
            Back
          </button>
        </div>
        <div className="container" style={{flexGrow: 1, marginLeft: "2px", marginRight: "2px"}}>
          <button 
            className="button secondary"
            onClick={this.props.onFetch}
            disabled={this.props.requestInProgress}
          >
            Fetch videos
          </button>
        </div>
        <div className="container" style={{flexGrow: 1, marginLeft: "2px"}}>
          <button
            className="button"
            onClick={this.props.onNext}
            disabled={this.props.requestInProgress || !this.props.isVideosFetched}
          >
            Next
          </button>
        </div>
      </div>
    );
  }
}

class PreviewVideos extends React.Component {
  render() {
    if (!this.props.videos) {
      return null;
    } else {
      return (
        <div className="container">
          <hr />
          {
            this.props.videos.map(function(video) {
              return (
                <PreviewVideoItem
                  key={video.date}
                  thumbnail={video.thumbnail}
                  title={video.title}
                />
              )
            })
          }
        </div>
      );
    }
  }
}

function removeAlreadyEmbeddedVideos(videos, embeddedVideos) {
  console.log(`[Before removal] there are ${videos.length} videos`);
  videos = videos.filter(video => {
    let found = embeddedVideos.find(v => video.id === v.videoId);
    if (found) {
      console.log(`Embedded video found for ${found.videoId} where post title is ${found.posts[0].title}`);
      return false;
    } else {
      return true;
    }
  });
  console.log(`[After removal] there are ${videos.length} videos`);
  return videos;
}