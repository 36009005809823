const getYoutubeUserUrl = "https://pc08wywu82.execute-api.us-east-1.amazonaws.com/test";

export default function send(username, callback) {
  fetch(getYoutubeUserUrl + "?username=" + username, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'content-type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response.snippet && response.snippet.title) {
        callback(null, response);
      } else {
        callback("Couldn't find user");
      }
    })
    .catch((err) => {
      callback(err);
    });
};