import React from 'react'
import Root from './Root'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import { withStyles } from '@material-ui/core/styles'
import { addWebsite } from '../../actions/websites'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'


const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 400
  }
}

const AddWebsite = ({ classes, addWebsite, t }) => {

  const [values, setValues] = React.useState({
    url: '',
    username: '',
    password: ''
  });

  const onAddClick = () => {
    addWebsite({
      hostname: values.url,
      username: values.username,
      password: values.password
    })
  }

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  }

  return(
    <Root>
      <CssBaseline />
      <form className={classes.root}>
        <TextField
          id='url'
          label={t('app.websites.add.url')}
          margin='normal'
          variant='outlined'
          onChange={handleChange('url')}
        />
        <TextField
          id='username'
          label={t('app.websites.add.username')}
          margin='normal'
          variant='outlined'
          onChange={handleChange('username')}
        />
        <TextField
          id='password'
          label={t('app.websites.add.password')}
          margin='normal'
          variant='outlined'
          type='password'
          onChange={handleChange('password')}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={onAddClick}
        >
          {t('add')}
        </Button>
      </form>
    </Root>
  )
}

const mapDispatchToProps = {
  addWebsite
}

export default connect(null, mapDispatchToProps)(
  withStyles(styles)(
    withTranslation('mustensih')(AddWebsite)
  )
)
