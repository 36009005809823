import React from 'react'
import { Card, CardActionArea, CardActions, CardContent, CardHeader, CardMedia } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import Done from '@material-ui/icons/Done'
import { withTranslation } from 'react-i18next'


const styles = {
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    maxWidth: 345,
    margin: 20
  },
  media: {
    height: 200,
    width: '100%'
  },
  postDetails: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start'
  }
}

const PostItem = ({ classes, image, title, content, status, categoryId, website, onDelete, onApprove, onEdit, t }) => (
  <Card className={classes.card}>
    <CardHeader
      title={website.hostname}
      subheader={t('app.dashboard.post.category', {category: website.categories.find(c => c.id === categoryId).name})}
    />
    <CardActionArea className={classes.postDetails}>
      <CardMedia
        className={classes.media}
        image={image}
        title={title}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {title}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {`${content.substring(0, 200)}...`}
        </Typography>
      </CardContent>
    </CardActionArea>
    <CardButtons
      classes={classes}
      status={status}
      onApprove={onApprove}
      onDelete={onDelete}
      onEdit={onEdit}
      t={t}
    />
  </Card>
)

const CardButtons = ({ classes, onApprove, onDelete, onEdit, status, t }) => {
  if (status === 'CREATED') {
    return (
      <CardActions>
        <Button color="primary" onClick={() => onApprove()}>
          {t('approve')}
        </Button>
        <Button onClick={() => onEdit()}>
          {t('edit')}
        </Button>
        <Button color="secondary" onClick={() => onDelete()}>
          {t('delete')}
        </Button>
      </CardActions>
    )
  } else {
    return (
      <CardActions>
        <Button disabled={true}>
          <Done /> {mapPostStatusToString(status, t)}
        </Button>
      </CardActions>
    )
  }
}

function mapPostStatusToString(status, t) {
  const map = {
    'APPROVED': t('app.dashboard.approved'),
    'PUBLISHED': t('app.dashboard.published'),
    'DELETED': t('app.dashboard.deleted')
  }

  return map[status];
}
 
export default withStyles(styles)(
  withTranslation('mustensih')(PostItem)
)
