export default function(videos, template) {
  if (!template) {
    template = {
      content: "{{videoDescription}}<!--more-->\n\n{{videoEmbed}}",
      embed: {
        width: 720,
        height: 480
      }
    };
  }

  let videosWithContent = videos.slice();

  videosWithContent.forEach(video => {
    if (template.embed && template.embed.width) {
      video.embed = video.embed.replace(/width="[0-9]*"/g, "width=\"" + template.embed.width + "\"");
    }

    if (template.embed && template.embed.height) {
      video.embed = video.embed.replace(/height="[0-9]*"/g, "height=\"" + template.embed.height + "\"");
    }

    let wpContent = template.content;
    wpContent = wpContent.replace(/\{\{videoDescription\}\}/g, video.description);
    wpContent = wpContent.replace(/\{\{videoEmbed\}\}/g, video.embed);

    video.wpContent = wpContent;
  });

  return videosWithContent;
}