export default {
  mustensih: {
    'app.dashboard': 'Dashboard',
    'app.websites': 'Websites',
    'app.youtube_accounts': 'Youtube Accounts',
    'app.rules': 'Rules',
    'app.sign_out': 'Sign out',

    // Dashboard page
    'app.dashboard.error.no_post_found': 'No posts found in this state',
    'app.dashboard.pending_approval': 'Pending Approval',
    'app.dashboard.approved': 'Approved',
    'app.dashboard.published': 'Published',
    'app.dashboard.deleted': 'Deleted',

    'app.dashboard.action.approve': 'Approve',
    'app.dashboard.action.edit': 'Edit',
    'app.dashboard.action.delete': 'Delete',

    'app.dashboard.post.category': 'Category: {{category}}',

    // Websites Page
    'app.websites.website': 'Website',

    // Add Website Page
    'app.websites.add.url': 'Url',
    'app.websites.add.username': 'Username',
    'app.websites.add.password': 'Password',

    // Sign out page
    'app.sign_out.info.signing_out': 'Signing out...',

    // Youtube Accounts Page
    'app.youtube_accounts.youtube_account': 'YouTube Account',

    // Add Youtube Account Page
    'app.youtube_accounts.add.channel_id': 'Channel Id',

    // Post Edit Page
    'app.post_edit.title': 'Title',
    'app.post_edit.content': 'Content',
    
    // Generic
    'add': 'Add',
    'approve': 'Approve',
    'edit': 'Edit',
    'delete': 'Delete',
    'save': 'Save'
  }
};