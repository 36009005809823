import Root from '../Root'
import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Paper, Tabs, Tab } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { fetchPosts, deletePost, approvePost, updatePost } from '../../../actions/posts'
import EditPostDialog from '../../dialogs/EditPostDialog'
import DashboardPostsTab from '../../../components/DashboardPostsTab'

const styles = {
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  }
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

class Dashboard extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isEditDialogOpen: false,
      postInEdit: null,
      tabIndex: 0
    }
  }

  handleEditClick(post) {
    this.setState({...this.state, isEditDialogOpen: true, postInEdit: post})
  }

  handleEditSave(post) {
    this.props.updatePost(post);
    this.setState({...this.state, isEditDialogOpen: false, postInEdit: null})
  }

  handleEditClose() {
    this.setState({...this.state, isEditDialogOpen: false, postInEdit: null})
  }

  handleTabIndexChange(event, newIndex) {
    this.setState({...this.state, tabIndex: newIndex})
  }

  render() {
    const { classes, fetchPosts, posts, deletePost, approvePost, websites, t } = this.props

    if (!posts.isSynced && !posts.inProgress) {
      fetchPosts()
    }

    if (posts.all.length > 0) {
      return (
        <Root>
          <Paper>
            <Tabs
              value={this.state.tabIndex}
              onChange={(e, i) => this.handleTabIndexChange(e, i)}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              <Tab label={t('app.dashboard.pending_approval')} {...a11yProps(0)} />
              <Tab label={t('app.dashboard.approved')} {...a11yProps(1)} />
              <Tab label={t('app.dashboard.published')} {...a11yProps(2)} />
              <Tab label={t('app.dashboard.deleted')} {...a11yProps(3)} />
            </Tabs>
          </Paper>
          <DashboardPostsTab
            value={this.state.tabIndex}
            classes={classes}
            posts={posts}
            websites={websites}
            status='CREATED'
            index={0}
            deletePost={deletePost}
            approvePost={approvePost}
            handleEditClick={p => this.handleEditClick(p)}
            t={t}
          />
          <DashboardPostsTab
            value={this.state.tabIndex}
            classes={classes}
            posts={posts}
            websites={websites}
            status='APPROVED'
            index={1}
            deletePost={deletePost}
            approvePost={approvePost}
            handleEditClick={p => this.handleEditClick(p)}
            t={t}
          />
          <DashboardPostsTab
            value={this.state.tabIndex}
            classes={classes}
            posts={posts}
            websites={websites}
            status='PUBLISHED'
            index={2}
            deletePost={deletePost}
            approvePost={approvePost}
            handleEditClick={p => this.handleEditClick(p)}
            t={t}
          />
          <DashboardPostsTab
            value={this.state.tabIndex}
            classes={classes}
            posts={posts}
            websites={websites}
            status='DELETED'
            index={3}
            deletePost={deletePost}
            approvePost={approvePost}
            handleEditClick={p => this.handleEditClick(p)}
            t={t}
          />
          { this.state.postInEdit &&
            (<EditPostDialog
              inputPost={this.state.postInEdit}
              website={this.state.postInEdit && websites.find(w => w.hostname === this.state.postInEdit.hostname)}
              open={this.state.isEditDialogOpen}
              onSave={(p) => this.handleEditSave(p)}
              onClose={() => this.handleEditClose()}
            />)
          }
        </Root>
      )
    } else {
      return (<Root>
        <div className={classes.root}>
        </div>
      </Root>)
    }
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    posts: state.posts,
    websites: state.websites.list
  }
}

const mapDispatchToProps = {
  fetchPosts,
  deletePost,
  approvePost,
  updatePost
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles)(
    withTranslation('mustensih')(Dashboard)
  )
)
