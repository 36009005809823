import {
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE
} from '../actions/user'

const user = (user, action) => {
  if (typeof user === 'undefined') {
    return {
      signedIn: false
    }
  }

  switch (action.type) {
    case REGISTER_USER_REQUEST:
      return {
        signedIn: false
      }
    case REGISTER_USER_SUCCESS:
      return {
        signedIn: true,
        ...action.user
      }
    case REGISTER_USER_FAILURE:
      return {
        signedIn: false,
        error: action.error
      }
    default:
      return user
  }
}

export default user