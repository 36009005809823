import React from 'react';
// import '../../index.css';
import Header from '../../components/Header';
import Navigation from '../../components/Navigation';
import Content from '../../content';
import YouTubeInfo from '../../forms/YouTubeInfo';
import YouTubeVideos from '../../forms/YouTubeVideos';
import WordpressInfo from '../../forms/WordpressInfo';
import CompletionInfo from '../../forms/CompletionInfo';
import Template from '../../forms/Template';
import createContent from '../../requests/createContent';

export default class SingleUse extends React.Component {
  static STEPS = {
    WordpressInfo: 0,
    YouTubeInfo: 1,
    Template: 2,
    YouTubeVideos: 3,
    CompletionInfo: 4
  };

  state = {
    step: SingleUse.STEPS.WordpressInfo,
    template: {
      content: "{{videoDescription}}<!--more-->\n\n{{videoEmbed}}",
      embed: {
        width: 720,
        height: 480
      }
    }
  };

  handleVideoSelection(videos) {
    this.setState(Object.assign({}, this.state, {
      step: SingleUse.STEPS.CompletionInfo,
      videos: videos
    }));
  }

  handleVideos(data) {
    var newState = Object.assign({}, this.state);
    newState.step = SingleUse.STEPS.Template;
    newState.videos = data;
    newState.videos.forEach(video => {
    var newState = Object.assign({}, this.state);
      video.category = newState.wp.categories[0].id;
    });
    this.setState(newState);
  }

  handleBack = () => {
    this.setState(Object.assign({}, this.state, {
      step: this.state.step-1
    }))
  }

  handleWPInfo = (wpInfo) => {
    this.setState(Object.assign({}, this.state, {
      wp: Object.assign({}, wpInfo)
    }));
    this.showYouTubeInfoPage();
  }

  handleTemplate = (template) => {
    this.setState(Object.assign({}, this.state, {
      template: template,
      step: SingleUse.STEPS.YouTubeVideos,
      videos: createContent(this.state.videos, template)
    }));
  }

  showCompletionPage() {
    this.setState(Object.assign({}, this.state, {
      step: SingleUse.STEPS.CompletionInfo
    }));
  }

  showWordpressInfoPage() {
    this.setState(Object.assign({}, this.state, {
      step: SingleUse.STEPS.WordpressInfo
    }));
  }

  handleDone() {
    this.showWordpressInfoPage();
  }

  showYouTubeInfoPage() {
    this.setState(Object.assign({}, this.state, {
      step: SingleUse.STEPS.YouTubeInfo
    }));
  }

  createForm() {
    switch(this.state.step) {
      case SingleUse.STEPS.YouTubeInfo:
        return (
          <YouTubeInfo
            embeddedVideos={this.state.wp.embeddedVideos}
            onNext={(data) => this.handleVideos(data)}
            onBack={this.handleBack}
          />
        );
      case SingleUse.STEPS.Template:
        return (
          <Template
            onNext={(template) => this.handleTemplate(template)}
            onBack={this.handleBack}
            template={this.state.template}
          />
        );
      case SingleUse.STEPS.YouTubeVideos:
        return (
          <YouTubeVideos
            categories={this.state.wp.categories}
            videos={this.state.videos}
            onNext={(videos) => this.handleVideoSelection(videos)}
            onBack={this.handleBack}
            hostname={this.state.wp.hostname}
            username={this.state.wp.username}
            password={this.state.wp.password}
          />
        );
      case SingleUse.STEPS.WordpressInfo:
        return (
          <WordpressInfo
            videos={this.state.videos}
            onNext={this.handleWPInfo.bind(this)}
            onBack={this.handleBack.bind(this)}
          />
        );
      case SingleUse.STEPS.CompletionInfo:
        return (
          <CompletionInfo
            videos={this.state.videos}
            onDone={this.handleDone.bind(this)}
          />
        );
      default:
        return <div></div>
    }
  }

  render() {
    return (
      <div>
        <Header
          title={Content.en.header.title}
          content={Content.en.header.content}
        />
        <Navigation step={this.state.step} />
        <div className="container">
          <div className="container form-container">
            {this.createForm()}
          </div>
        </div>
      </div>
    );
  }
}
