import React from 'react'
import ReactDOM from 'react-dom'
import Website from './Website'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'
import { ThemeProvider } from '@material-ui/styles'
import { I18nextProvider } from 'react-i18next'
import thunkMiddleware from 'redux-thunk'
import theme from './theme'
import app from './reducers'
import i18n from './i18n'

const loggerMiddleware = createLogger() 

const store = createStore(
  app,
  undefined,
  applyMiddleware(
    thunkMiddleware,
    loggerMiddleware
  )
)

ReactDOM.render(
  <I18nextProvider i18n={ i18n }>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Website />
      </ThemeProvider>
    </Provider>
  </I18nextProvider>,
  document.getElementById('root')
)
