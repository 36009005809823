import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Dashboard, ExitToApp, Public, VideoLibrary, Share } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';


const MenuItems = ({ t }) => (
  <List>
    <div>
      <ListItem button component={Link} to="/app/dashboard">
          <ListItemIcon>
            <Dashboard />
          </ListItemIcon>
          <ListItemText primary={t('app.dashboard')} />
      </ListItem>
      <ListItem button component={Link} to="/app/websites">
        <ListItemIcon>
          <Public />
        </ListItemIcon>
        <ListItemText primary={t('app.websites')} />
      </ListItem>
      <ListItem button component={Link} to="/app/youtubeaccounts">
        <ListItemIcon>
          <VideoLibrary />
        </ListItemIcon>
        <ListItemText primary={t('app.youtube_accounts')} />
      </ListItem>
      <ListItem button disabled component={Link} to="/app/rules">
        <ListItemIcon>
          <Share />
        </ListItemIcon>
        <ListItemText primary={t('app.rules')} />
      </ListItem>
      <ListItem button component={Link} to="/signout">
        <ListItemIcon>
          <ExitToApp />
        </ListItemIcon>
        <ListItemText primary={t('app.sign_out')} />
      </ListItem>
    </div>
  </List>
);

export default withTranslation('mustensih')(MenuItems);
