import {
  FETCH_POSTS_REQUEST,
  FETCH_POSTS_SUCCESS,
  FETCH_POSTS_FAILURE
} from "../actions/posts"

export default function posts(posts, action) {
  if (typeof posts === 'undefined') {
    return {
      isSynced: false,
      inProgress: false,
      all: []
    }
  }

  switch(action.type) {
    case FETCH_POSTS_REQUEST:
      return {
        ...posts,
        inProgress: true,
        isSynced: false,
        all: []
      }
    case FETCH_POSTS_SUCCESS:
      return {
        ...posts,
        inProgress: false,
        isSynced: true,
        all: action.posts
      }
    case FETCH_POSTS_FAILURE:
      return {
        ...posts,
        inProgress: false,
        isSynced: false
      }
    default:
      return posts
  }
}
