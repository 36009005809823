import React, { useState } from 'react'
import { 
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  DialogContent,
  Button,
  TextField,
  Select,
  MenuItem
} from "@material-ui/core"
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import { withTranslation } from 'react-i18next';


const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  input: {
    marginTop: 30
  }
}));

function EditPostDialog({ open=false, onSave, onClose, inputPost, website, t }) {
  const classes = useStyles();

  const [post, setPost] = useState(inputPost)

  const handleCategoryChange = event => {
    setPost({...post, categories: [event.target.value]})
  }

  const handleTitleChange = event => {
    setPost({...post, title: event.target.value})
  }

  const handleContentChange = event => {
    setPost({...post, content: event.target.value})
  }

  if (!post) {
    return null;
  }

  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <AppBar>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {post.title}
          </Typography>
          <Button autoFocus color="inherit" onClick={() => onSave(post)}>
            {t('save')}
          </Button>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <DialogContent>
        <TextField
          className={classes.input}
          autoFocus
          id='title'
          label={t('app.post_edit.title')}
          fullWidth
          defaultValue={post.title}
          onChange={handleTitleChange}
        />
        <TextField
          className={classes.input}
          id='content'
          label={t('app.post_edit.content')}
          fullWidth
          multiline
          defaultValue={post.content}
          onChange={handleContentChange}
        />
        <Select
          className={classes.input}
          id='category'
          value={post.categories[0]}
          onChange={handleCategoryChange}
          fullWidth
        >
          { 
            website.categories.map(c => {
              return (
                <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
              )
            })
          }
        </Select>
      </DialogContent>
    </Dialog>
  )
}

export default withTranslation('mustensih')(EditPostDialog);
