import { API } from 'aws-amplify'

// Add Youtube Account

export const ADD_YT_ACCOUNT_REQUEST = "ADD_YT_ACCOUNT_REQUEST"
export const ADD_YT_ACCOUNT_SUCCESS = "ADD_YT_ACCOUNT_SUCCESS"
export const ADD_YT_ACCOUNT_FAILURE = "ADD_YT_ACCOUNT_FAILURE"

export const addAccount = (account) => (dispatch, getState) => {
  dispatch({ type: ADD_YT_ACCOUNT_REQUEST })

  const { user } = getState()
  const params = {
    body: {
      channelId: account.accountId,
      userId: user.id
    }
  }

  API.post('mustensih-api', '/user/youtubechannel', params)
      .then(response => dispatch({ type: ADD_YT_ACCOUNT_SUCCESS, account }))
      .catch(error => dispatch({ type: ADD_YT_ACCOUNT_FAILURE, error }))
}

// Fetch Youtube Accounts
export const FETCH_YT_ACCOUNTS_REQUEST = "FETCH_YT_ACCOUNTS_REQUEST"
export const FETCH_YT_ACCOUNTS_SUCCESS = "FETCH_YT_ACCOUNTS_SUCCESS"
export const FETCH_YT_ACCOUNTS_FAILURE = "FETCH_YT_ACCOUNTS_FAILURE"

export const fetchAccounts = () => (dispatch, getState) => {
  const { user } = getState()
  if (!user.signedIn) return;
  
  dispatch({ type: FETCH_YT_ACCOUNTS_REQUEST })
  
  API.get('mustensih-api', `/user/youtubechannel?userId=${user.id}`)
      .then(response => dispatch({ type: FETCH_YT_ACCOUNTS_SUCCESS, accounts: response }))
      .catch(error => dispatch({ type: FETCH_YT_ACCOUNTS_FAILURE, error }))
}

// Delete Website
export const DELETE_YT_ACCOUNT_REQUEST = "DELETE_YT_ACCOUNT_REQUEST"
export const DELETE_YT_ACCOUNT_SUCCESS = "DELETE_YT_ACCOUNT_SUCCESS"
export const DELETE_YT_ACCOUNT_FAILURE = "DELETE_YT_ACCOUNT_FAILURE"

export const deleteAccount = (id) => (dispatch, getState) => {
  const { user } = getState()
  if (!user.signedIn) return;
  dispatch({ type: DELETE_YT_ACCOUNT_REQUEST })

  const params = {
    body: {
      channelId: id,
      userId: user.id
    }
  }

  API.del('mustensih-api', '/user/youtubechannel', params)
      .then(() => dispatch({ type: DELETE_YT_ACCOUNT_SUCCESS }))
      .catch(error => dispatch({ type: DELETE_YT_ACCOUNT_FAILURE, error }))
}
