import React from 'react';

export default class NavigationItem extends React.Component {
  static imgs = {
    done: "img/tick-green.png",
    current: "img/tick-grey.png",
    disabled: "img/tick-grey.png"
  };

  render() {
    return (
      <div className="navigation-item" state={this.props.state} position={this.props.position} >
        <img src={NavigationItem.imgs[this.props.state]} alt="" style={{width:"24px", height:"24px", marginRight:"5px"}}></img>
        {this.props.text}
      </div>
    );
  }
}