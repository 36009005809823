// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:82b819bd-90d5-44e3-af10-5f0d021ed00b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_lL4q44hwp",
    "aws_user_pools_web_client_id": "72p05j47ppt82op872jfncnnle",
    "oauth": {},
    "aws_content_delivery_bucket": "mustensih-webclient-mustensih",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://mustensih-webclient-mustensih.s3-website-us-east-1.amazonaws.com",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "websites-mustensih",
            "region": "us-east-1"
        },
        {
            "tableName": "ytaccounts-mustensih",
            "region": "us-east-1"
        },
        {
            "tableName": "posts-mustensih",
            "region": "us-east-1"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "dbapi",
            "endpoint": "https://0cetxty5j3.execute-api.us-east-1.amazonaws.com/mustensih",
            "region": "us-east-1"
        },
        {
            "name": "ytdbapi",
            "endpoint": "https://9d7trmcju5.execute-api.us-east-1.amazonaws.com/mustensih",
            "region": "us-east-1"
        },
        {
            "name": "postsdbapi",
            "endpoint": "https://wwp0vpotm4.execute-api.us-east-1.amazonaws.com/mustensih",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
