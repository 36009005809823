import React from 'react'
import Modal from 'react-modal'
import Select from 'react-select'

export default class VideoEditModal extends React.Component {
  state = {
    video: {}
  }

  handleTitleChange = (event) => {
    this.setState({
      video: Object.assign({}, this.state.video, {
        title: event.target.value
      })
    });
  }

  handleContentChange = (event) => {
    this.setState({
      video: Object.assign({}, this.state.video, {
        wpContent: event.target.value
      })
    });
  }

  handleClose = () => {
    this.props.onEditCancelled();
  }

  handleSave = () => {
    this.props.onEditCompleted(this.state.video);
  }

  handleAfterOpen = () => {
    this.setState({
      video: Object.assign({}, this.props.video)
    });
  }

  handleCategoryChange = (selected) => {
    this.setState({
      video: Object.assign({}, this.state.video, {
        category: selected.value
      })
    });
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={this.handleClose}
        onAfterOpen={this.handleAfterOpen.bind(this)}
        style={{
          content: {
            left: '25%',
            right: '25%',
            top: '10%',
            bottom: '10%'
          }
        }}
      >
        <div className="edit-modal">
          <div className="form-header" align="center">
            <span className="header">
              Edit Wordpress Post
            </span>
          </div>
          <div className="container">
            <b>Title:</b>
            <input
              type="text"
              value={this.state.video.title}
              onChange={this.handleTitleChange}
            />
          </div>
          <div className="container">
            <b>Wordpress Content:</b>
            <textarea rows={15} value={this.state.video.wpContent} onChange={this.handleContentChange}/>
          </div>
          <div className="container" style={{flex: 1}}>
            <b>Category:</b>
            <Select
              value={this.props.categories.filter((category) => { return this.state.video.category === category.id}).map(category => {return { value: category.id, label: category.name}})[0]}
              onChange={this.handleCategoryChange.bind(this)}
              options={ this.props.categories.map((category) => { return { value: category.id, label: category.name } }) }
            />
          </div> 
          <button style={{justifySelf: "flex-end"}} onClick={this.handleSave} className="button">
            Save
          </button>
        </div>
      </Modal>
    );
  }
}