import {
  ADD_YT_ACCOUNT_REQUEST,
  ADD_YT_ACCOUNT_SUCCESS,
  ADD_YT_ACCOUNT_FAILURE,
  DELETE_YT_ACCOUNT_REQUEST,
  DELETE_YT_ACCOUNT_SUCCESS,
  DELETE_YT_ACCOUNT_FAILURE,
  FETCH_YT_ACCOUNTS_FAILURE,
  FETCH_YT_ACCOUNTS_REQUEST, 
  FETCH_YT_ACCOUNTS_SUCCESS} from '../actions/accounts'

function accounts(accounts, action) {
  if (typeof accounts === 'undefined') {
    return {
      isSynced: false,
      list: []
    }
  }

  switch(action.type) {
    case ADD_YT_ACCOUNT_REQUEST:
    case DELETE_YT_ACCOUNT_REQUEST:
      return {
        ...accounts,
        inProgress: true,
        isSynced: false
      }
    case ADD_YT_ACCOUNT_SUCCESS:
    case DELETE_YT_ACCOUNT_SUCCESS:
      return {
        ...accounts,
        inProgress: false,
        isSynced: false
      }
    case ADD_YT_ACCOUNT_FAILURE:
    case DELETE_YT_ACCOUNT_FAILURE:
      return {
        inProgress: false,
        isSynced: false,
        list: []
      }
    case FETCH_YT_ACCOUNTS_REQUEST:
      return {
        inProgress: true,
        isSynced: false,
        list: []
      }
    case FETCH_YT_ACCOUNTS_SUCCESS:
      return {
        inProgress: false,
        isSynced: true,
        list: action.accounts
      }
    case FETCH_YT_ACCOUNTS_FAILURE:
      return {
        ...accounts,
        inProgress: false,
        isSynced: false
      }
    default:
      return accounts
  }
}

export default accounts