import Root from './Root'
import React from 'react'
import ItemCardList from '../../components/ItemCardList'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import { connect } from 'react-redux'
import { fetchWebsites, deleteWebsite } from '../../actions/websites'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'


const styles = {
  root: {
    display: 'flex',
    alignItems: 'center'
  }
}

class Websites extends React.Component {

  render() {
    const { props } = this
    const { classes, websites, fetchWebsites, t } = this.props

    if (!websites.inProgress && !websites.isSynced) {
      fetchWebsites()
    }

    const items = websites.list.map(website => {
      return {
        id: website.hostname,
        type: t('app.websites.website'),
        title: website.hostname,
        subtitle: website.username
      }
    })

    return (
      <Root>
        <div className={classes.root}>
          <ItemCardList
            items={items}
            onDeleteClick={props.deleteWebsite}
            onAddClick={() => { props.history.push('/app/websites/add') }}
          />
          <Fab href="/app/websites/add" color="primary" aria-label="Add">
            <AddIcon />
          </Fab>
        </div>
      </Root>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    websites: state.websites
  }
}

const mapDispatchToProps = {
  fetchWebsites,
  deleteWebsite
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles)(
    withRouter(
      withTranslation('mustensih')(Websites)
    )
  )
)