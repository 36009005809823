import React from 'react'
import Root from './Root'
import PropTypes from 'prop-types'
import ItemCardList from '../../components/ItemCardList'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import { connect } from 'react-redux'
import { fetchAccounts, deleteAccount } from '../../actions/accounts'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center'
  }
}

class YoutubeAccounts extends React.Component {

  render() {
    const { props } = this
    const { classes, accounts, t } = this.props

    if (!accounts.inProgress && !accounts.isSynced) props.fetchAccounts()

    const items = accounts.list.map(account => {
      return {
        id: account.channelId,
        type: t('app.youtube_accounts.youtube_account'),
        title: account.channelName,
        subtitle: ""
      }
    })

    return (
      <Root>
        <div className={classes.root}>
          <ItemCardList
            items={items}
            onDeleteClick={props.deleteAccount}
            onAddClick={() => { props.history.push('/app/youtubeaccounts/add') }}
          />
          <Fab href="/app/youtubeaccounts/add" color="primary" aria-label="Add">
            <AddIcon />
          </Fab>
        </div>
      </Root>
    );
  }
}

YoutubeAccounts.propTypes = {
  accounts: PropTypes.object.isRequired
}

const mapStateToProps = state => {
  return {
    user: state.user,
    accounts: state.accounts
  }
}

const mapDispatchToProps = {
  fetchAccounts,
  deleteAccount
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles)(
    withRouter(
      withTranslation('mustensih')(YoutubeAccounts)
    )
  )
)