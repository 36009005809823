import React from 'react';
import VideoItem from '../components/VideoItem.js';
import VideoEditModal from '../components/VideoEditModal.js';
import createPosts from '../requests/wordpressPosts'

export default class YouTubeVideos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      videos: props.videos.slice(),
      modalIsOpen: false,
      requestInProgress: false
    }
  }

  openModal() {
    this.setState(Object.assign({}, this.state, {
      modalIsOpen: true
    }));
  }

  closeModal = () => {
    this.setState(Object.assign({}, this.state, {
      modalIsOpen: false
    }));
  }

  handleEdit(video) {
    console.log(video);
    this.setState(Object.assign({}, this.state, {
      modalIsOpen: true,
      videoToEdit: video,
    }));
  }

  saveChanges = (changedVideo) => {
    let originalVideo = this.state.videoToEdit;
    originalVideo.title = changedVideo.title;
    originalVideo.wpContent = changedVideo.wpContent;
    originalVideo.category = changedVideo.category;

    this.setState(Object.assign({}, this.state, {
      videoToEdit: null,
      modalIsOpen: false
    }));
  }

  cancelChanges = () => {
    this.setState(Object.assign({}, this.state, {
      videoToEdit: null,
      modalIsOpen: false
    }));
  }

  handleDelete(video) {
    let newVideos = this.state.videos.filter(v => v.date !== video.date);
    this.setState({
      videos: newVideos
    });
  }

  handleNext() {
    this.setState(Object.assign({}, this.state, {
      requestInProgress: true
    }));

    createPosts({
        hostname: this.props.hostname,
        username: this.props.username,
        password: this.props.password,
        videos: this.state.videos
      }, (err, result) => {
        if (err) {
          this.setState(Object.assign({}, this.state, {
            requestInProgress: false,
            errorMessage: err.toString()
          }));
        } else {
          this.setState(Object.assign({}, this.state, {
            requestInProgress: false,
            errorMessage: null
          }));
          this.props.onNext(this.state.videos);
        }
      }
    );
  }

  findCategoryNameById = (id) => {
    return this.props.categories.filter(category => id === category.id)[0].name;
  }

  render() {
    return (
      <div className="container">
        {/* HEADER */}
        <div className="form-header" align="center">
          <span className="header">Confirm YouTube videos</span><br/>
          <span className="small">There will be one blog post for each videos on this step</span>
          <hr />
        </div>
        {/* BUTTONS */}
        <div className="container" style={{flexDirection:"row", width:"100%"}}>
          <button className="button secondary" style={{marginRight:"5px", flexGrow:1}} onClick={this.props.onBack}>
            Back
          </button>
          <button className="button" style={{marginLeft:"5px", flexGrow:1}} onClick={this.handleNext.bind(this)} disabled={this.state.requestInProgress}>
            Create Posts
          </button>
        </div>
        <div style={{height:"20px"}}></div>
        {/* VIDEOS */}
        {
          Array.isArray(this.state.videos) &&
          this.state.videos.map(function(video) {
            return (
              <VideoItem
                key={video.date}
                thumbnail={video.thumbnail}
                title={video.title}
                wpContent={video.wpContent}
                category={video.category}
                categoryName={this.findCategoryNameById(video.category)}
                onEdit={() => this.handleEdit(video)}
                onDelete={() => this.handleDelete(video)}
              />
            )
        }.bind(this))}
        <VideoEditModal
          isOpen={this.state.modalIsOpen}
          video={this.state.videoToEdit}
          onEditCompleted={this.saveChanges}
          onEditCancelled={this.cancelChanges}
          categories={this.props.categories}
        >
        </VideoEditModal>
      </div>
    );
  }
}
